import React, { ReactNode } from 'react'
import { styled } from '@mui/material'
import classNames from 'classnames'

import { HELPER_TYPE_ERROR, HELPER_TYPE_SUCCESS, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'

const StyledError = styled('span')<{ variant?: Variant }>(
  ({ theme, variant }) => `
  display: block;
  color: ${theme.colours.danger};
  margin-top: 0.1rem;
  display: block;
  margin-bottom: 0;
  font-size: 14px;

  margin-left: ${variant === 'compact' ? 'calc(33% + 4px)' : variant === 'compactLeftAligned' ? 'calc(40%)' : 0};
  font-size: ${variant === 'compact' || variant === 'compactLeftAligned' ? `12px !important` : `14px !important`};

  &.error {
    color: ${theme.colours.danger};
    a,
    a:hover {
      color: ${theme.colours.danger};
    }
  }

  &.success {
    color: ${theme.colours.successColor};
  }
`,
)

export type HelperTextProps = {
  message: ReactNode
  textType?: string
  variant?: Variant
  id?: string
}

const HelperText = ({ id, message, variant = 'default', textType }: HelperTextProps) => {
  const dataTestId = id ? testHandle(id) : id
  const className = classNames({
    error: textType === HELPER_TYPE_ERROR,
    success: textType === HELPER_TYPE_SUCCESS,
  })

  return (
    <StyledError className={className} data-testid={`${dataTestId}-error`} variant={variant}>
      {message}
    </StyledError>
  )
}

export default HelperText
